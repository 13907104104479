<template>
    <v-container class="medium-container">
        <v-row>
            <v-col cols="5" sm="4" md="3" lg="2">
                <div class="image" :style="`background-image: url(${artist.profile});`"></div>
            </v-col>
            <v-col md="6">
                <v-row>
                    <v-col cols="12">
                        <h1>{{ artist.name }}</h1>
                        <p>{{ artist.description }}</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ArtistProfile',
    props: {
        artist: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
    .medium-container{
        margin-top: 10vh;
        max-width: 1185px;
    }
    @media (min-width: 1904px) {
        .medium-container {
            max-width: 1770px !important;
        }
    }
    @media (min-width: 1264px) {
        .medium-container {
            max-width: 1185px !important;
        }
    }
    @media (min-width: 960px) {
        .medium-container {
            max-width: 890px;
        }
    }


    .image {
        height: 400px;
        background-size: 101%;
        background-position: calc(100%) calc(33%);
        /* border-radius: 5px; */
        border: 1pt solid var(--color-primary);
    }

    
    @media (min-width: 960px) {
        .image {
            height: 225px !important;
        }
    }
    @media (min-width: 1024px) {
        .image {
            height: 180px !important;
        }
    }
    @media (min-width: 1441px) {
        .image {
            height: 188px !important;
        }
    }
    @media (min-width: 600px) {
        .image {
            height: 230px;
        }
    }
    @media (max-width: 600px) {
        .image {
            height: 220px;
        }
    }

    h1{
        font-size: 2.3em;
        font-weight: 700;
        color: var(--color-primary);
        font-family: "Tomorrow", sans-serif !important;
    }

    p{
        font-size: 1em;
        font-family: "Inter", sans-serif !important;
    }
</style>