import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// Import views
import HomeView from '@/views/HomeView.vue'
import ArtistsView from '@/views/ArtistsView.vue'
import ProductsView from '@/views/ProductsView.vue'
import ProfileView from '@/views/ProfileView.vue'
import ProductView from '@/views/ProductView.vue'
import CartView from '@/views/CartView.vue'
import PaymentView from '@/views/PaymentView.vue'

let routes = [
  { 
    path: '/', 
    component: HomeView, 
  },
  {
    path: '/artistas',
    component: ArtistsView
  },
  {
    path: '/produtos',
    component: ProductsView
  },
  {
    path: '/perfil/:slug',
    component: ProfileView
  },
  {
    path: '/produto/:slug',
    component: ProductView
  },
  {
    path: '/carrinho',
    component: CartView
  },
  {
    path: '/pagamento',
    component: PaymentView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
