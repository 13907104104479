<template>
     <v-row ref="artists" class="artists sticky-p">
        <v-col cols="12" class="title">
            <h1 v-if="mobile" @click="open = !open">
                FILTROS 
                <v-icon color="black" style="margin-top: -6px" v-if="open">fa fa-minus</v-icon>
                <v-icon color="black" style="margin-top: -6px" v-if="!open">fa fa-plus</v-icon>
            </h1>
            <h1 v-if="!mobile">FILTROS</h1>
        </v-col>
        <v-col cols="12" v-if="open">
            <h2>PREÇO</h2>
            <small>DE {{value[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</small><br>
            <small>ATÉ {{value[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</small>
            <ul>
                <li>
                    <v-range-slider
                        v-model="value"
                        step="20"
                        max="200"
                        min="1"
                        color="#000"
                        track-color="#000"
                    ></v-range-slider>
                </li>
            </ul>

            <h2>TAMANHO</h2>
            <ul>
                <li v-for="item in sizes" :key="item.id">
                    <input type="checkbox" @change="emitAllFilters" :ref="`filter${item.id}`" :id="item.id"> <label :for="item.id">{{ item.name }}</label>
                </li>
            </ul>
            
            <button class="lda-btn lda-btn-black" v-if="mobile">APLICAR</button>

        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'FilterProduct',
    props: {
        mobile: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return{
            open: false,
            value: [1, 200],
            sizes: [
                { id: 3, name: 'PP' },
                { id: 4, name: 'P' },
                { id: 5, name: 'M' },
                { id: 6, name: 'G' },
                { id: 7, name: 'GG' },
            ]
        }
    },
    watch: {
        value() {
            if(!this.mobile) {
                this.emitAllFilters();
            }
        }
    },
    mounted() {
        if (this.mobile) {
            this.open = false;
            console.log(this.$refs.artists.classList.remove('sticky-p'));
        }else{
            this.open = true;
        }
    },
    methods: {
        emitAllFilters() {
            const sizes = this.sizes.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    selected: this.$refs[`filter${item.id}`][0].checked
                }
            });
            let filters = {
                price: this.value,
                sizes
            }
            this.$emit('handleFilters', filters);
        }
    }
};
</script>

<style scoped>
    .sticky-p {
        position: sticky;
        top: 9vh;
        background-color: var(--color-white);
        padding: 0px;
    }

    .title h1{
        font-size: 1.2em;
        font-weight: 700;
        color: var(--color-text);
        font-family: "Tomorrow", sans-serif !important;
    }

    h2 {
        font-size: 1em;
        font-weight: 700;
        color: var(--color-text);
        font-family: "Tomorrow", sans-serif !important;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        margin-bottom: 10px;
    }

    input[type="checkbox"] {
        display: none;
    }

    input[type="checkbox"] + label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
    }

    input[type="checkbox"] + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 17px;
        height: 17px;
        border: 1px solid #c7c7c7;
        background-color: var(--color-white);
        border-radius: 3px;
    }

    input[type="checkbox"]:checked + label:before {
        background-color: var(--color-primary);
        border: 1px solid var(--color-primary);
    }

    input[type="checkbox"] + label:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 3px;
        width: 8px;
        height: 13px;
        border: solid var(--color-white);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
    }

    input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }

</style>