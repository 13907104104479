<template>
    <v-container fluid class="footer">
        <v-row>
            <v-col>
                <v-container>
                    <v-row>
                        <v-col class="logo" cols="4" md="2" lg="2">
                            <img :src="require('@/assets/img/logo-meta.png')" alt="">
                        </v-col>
                        <v-col class="menu" cols="4" md="6" lg="6">
                            <v-btn class="menu-item" text @click="goTo('/')">Home</v-btn><br>
                            <v-btn class="menu-item" text @click="goTo('/artistas')">Artistas</v-btn><br>
                            <v-btn class="menu-item" text @click="goTo('/produtos')">Loja</v-btn>
                        </v-col>
                        <v-col class="social">
                            <h4>
                                Siga a LDA
                            </h4>
                            <v-btn icon>
                                <v-icon color="#000">fab fa-facebook</v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon color="#000">fab fa-instagram</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <span class="copy">© {{year}} Loja dos Artistas. Todos os direitos reservados.</span><br>
                            <span class="dev"> Desenvolvido por <a href="https://beeasy.digital" target="_blank">Beeasy</a></span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'FooterSection',
    data() {
        return {
            year: new Date().getFullYear()
        };
    },
    methods: {
        goTo(route) {
            this.$router.push(route)
        }   
    }
};
</script>

<style scoped>
    .footer{
        background-color: #fff;
        color: #000;
        padding-top: 50px;
        padding-bottom: 10px;
    }

    .logo img{
        width: 100px;
    }

    .social{
        text-align: right;
    }

    .social h4{
        margin-bottom: 10px;
    }

    .copy{
        font-weight: 300;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
    }

    .dev{
        font-size: 15px;
    }

    .dev a{
        text-decoration: none;
        color: #6c6;
        font-weight: bold
    }
</style>