<template>
    <v-app>
        <NavBar />
        <ProductsPage :products="products" @searchProduct="getProducts"/>
        <SearchSection />
        <FooterSection />
    </v-app>
</template>

<script>
import NavBar from '@/components/default/NavBar.vue';
import ProductsPage from '@/components/productspage/ProductsPage.vue';
import SearchSection from '@/components/default/SearchSection.vue';
import FooterSection from '@/components/default/FooterSection.vue';
import api from "@/commands/api"
export default {
    name: 'ProductsView',
    components: {
        NavBar,
        ProductsPage,
        SearchSection,
        FooterSection
    },
    data() {
        return {
            products: [],
            productsMeta: {}
        };
    },
    async created() {
        await this.getProducts();
    },
    methods: {
        async getProducts(search = '', filters = {}) {
            try {
                let q = '';
                q += '?o=sale_price';
                if(search) {
                    q = `?q=${search}`;
                }

                if(filters.price) {
                    q += `&min=${filters.price[0]}&max=${filters.price[1]}`;
                }

                if(filters.sizes) {
                    q += `&v=${filters.sizes.filter(item => item.selected).map(item => item.name).join(',')}`;
                }

                const response = await api.get(`/products${q}`);
                this.products = response.data.data;
                this.productsMeta = response.data.meta;
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>

<style scoped>
    
</style>