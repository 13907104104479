<template>
    <v-app>
        <NavBar />
        <BannerSlide />
        <HomePageArtists :artists="artists" />
        <HomePageProducts :products="products" />
        <SearchSection />
        <FooterSection />
    </v-app>
</template>

<script>
import NavBar from '@/components/default/NavBar.vue';
import BannerSlide from '@/components/default/BannerSlide.vue';
import HomePageArtists from '@/components/artists/HomePageArtists.vue';
import HomePageProducts from '@/components/products/HomePageProducts.vue';
import SearchSection from '@/components/default/SearchSection.vue';
import FooterSection from '@/components/default/FooterSection.vue';

import api from "@/commands/api"

export default {
    name: 'HomeView',
    components: {
        NavBar,
        BannerSlide,
        HomePageArtists,
        HomePageProducts,
        SearchSection,
        FooterSection
    },
    data() {
        return {
            artists: [],
            products:[],
            productsMeta: []
        };
    },
    async mounted(){
        await this.init()
    },
    methods:{
        async init(){
            await this.getArtists()
            await this.getProducts()
        },
        async getArtists(){
            try {
                let per_page = 3
                let page = 1
                let o = 'created_at'
                let ot = 'desc'

                let response = await api.get(`/stores?per_page=${per_page}&page=${page}&o=${o}&ot=${ot}`)
                this.artists = response.data.data
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erro ao listar artistas'
                })
            }
        },
        async getProducts(){
            try {
                let per_page = 9
                let page = 1
                let o = 'created_at'
                let ot = 'desc'

                let response = await api.get(`/products?per_page=${per_page}&page=${page}&o=${o}&ot=${ot}`)
                this.products = response.data.data
                this.productsMeta = response.data.meta
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erro ao listar produtos'
                })
            }
        }
    }
};
</script>

<style scoped>
    
</style>