<template>
    <v-app>
        <NavBar />
        <PaymentPage :products="products" @removeProduct="removeProduct"/>
        <SearchSection />
        <FooterSection />
    </v-app>
</template>

<script>
import NavBar from '@/components/default/NavBar.vue';
import PaymentPage from '@/components/paymentpage/PaymentPage.vue';
import SearchSection from '@/components/default/SearchSection.vue';
import FooterSection from '@/components/default/FooterSection.vue';
export default {
    name: 'PaymentView',
    components: {
        NavBar,
        PaymentPage,
        SearchSection,
        FooterSection
    },
    data() {
        return {
            products: []
        };
    },
    created() {
        this.getProductInCart();
    },
    methods: {
        async getProductInCart(){
            await this.$store.dispatch('updateCartIfLocalStorageExists');
            this.products = this.$store.getters.cart;
        },
        removeProduct(p){
            this.$store.dispatch('removeProductFromCart', p);
        }
    }
};
</script>

<style scoped>
    
</style>