<template>
    <v-app>
        <NavBar />
        <ProductPage v-if="!loading" :loading="loading" :product="product" :products="products"/>
        <SearchSection />
        <FooterSection />
    </v-app>
</template>

<script>
import NavBar from '@/components/default/NavBar.vue';
import ProductPage from '@/components/product/ProductPage.vue';
import SearchSection from '@/components/default/SearchSection.vue';
import FooterSection from '@/components/default/FooterSection.vue';
import api from "@/commands/api"
export default {
    name: 'ProductView',
    components: {
        NavBar,
        ProductPage,
        SearchSection,
        FooterSection
    },
    data() {
        return {
            loading: false,
            products:[],
            product:{}
        };
    },
    async mounted(){
        await this.init()
    },
    methods:{
        async init(){
            this.loading = true
            await this.getProduct()
            await this.getProducts()
            this.loading = false
        },
        async getProduct(){
           try {
            let slug = this.$route.params.slug;
            let response = await api.get(`/products/${slug}`);
            this.product = response.data;
           } catch (error) {
            console.log(error)
           }
        },
        async getProducts(){
            try {
                let per_page = 4
                let page = 1
                let o = 'created_at'
                let ot = 'desc'

                let response = await api.get(`/products?per_page=${per_page}&page=${page}&o=${o}&ot=${ot}`)
                this.products = response.data.data;
            } catch (error) {
                console.log(error)
            }
        }
    }
};
</script>

<style scoped>
    
</style>