<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',

  components: {
  },

  data(){
    return {
      
    }
  },
  async mounted(){
    await this.insertVisit()
  },
  watch: {
    async '$route' () {
      await this.$vuetify.goTo(0);
      //this.insertVisit()
    }
  },
  methods:{
    async insertVisit() {
      try {
        var ipdata = {}
        if(localStorage.getItem('fingerprint')){
          ipdata = JSON.parse(localStorage.getItem('fingerprint'))
        }else{
          let response = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=4afbf62ff3024a9f9e8e3e22ef334955")
          ipdata = response.data
          localStorage.setItem('fingerprint', JSON.stringify(ipdata))
        }
        let ua = `${ipdata.city}/${ipdata.region} - ${navigator.userAgent}`
        let fingerprint = {...ipdata, ua}
        console.log(fingerprint)
      } catch (error) {
        console.error(error)
      }
    },
  }
};
</script>
