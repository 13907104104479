<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="title">
                <h1>ARTISTAS</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-for="artist in artists"
                :key="artist.id"
                cols="12"
                sm="4"
                md="4"
                lg="4"
            >
                <ArtistCard :artist="artist" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="btn-view-all">
                <button class="lda-btn lda-btn-black text-md" @click="goTo('/artistas')">VEJA TODOS OS ARTISTAS</button>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ArtistCard from '@/components/default/ArtistCard.vue';
export default {
    name: 'HomePageArtists',
    components: {
        ArtistCard,
    },
    props: {
        artists: {
            type: Array,
            required: true,
        },
    },
    methods: {
        goTo(path) {
            this.$router.push(path);
        },
    },
};
</script>

<style scoped>
    .title {
        margin-top: 20px;
        margin-bottom: 30px;
        
    }
    .title h1{
        font-size: 2em;
        font-weight: 700;
        color: var(--color-primary);
        font-family: "Tomorrow", sans-serif !important;
        text-align: center;
    }
    .btn-view-all{
        padding-top: 15px;
        padding-bottom: 15px;
    }
</style>