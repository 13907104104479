<template>
    <v-container fluid class="search-section">
        <v-row>
            <v-col cols="12">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6" offset-md="3" lg="6" offset-lg="3">
                            <v-row>
                                <v-col>
                                    <h1>
                                        PROCURANDO POR ALGO <br><span>ESPECÍFICO?</span>
                                    </h1>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <div class="input-search">
                                        <input type="text">
                                        <button>PESQUISAR</button>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'SearchSection',
    data() {
        return {
        };
    },
};
</script>


<style scoped>
    .search-section {
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
        background: url('../../assets/img/banners/bg-orange.png') no-repeat center center;
    }
    h1{
        font-size: 2.2em;
        color: #fff;
        text-align: center;
        font-family: 'Tomorrow', sans-serif;
    }

    h1 span{
        font-family: 'Tomorrow', sans-serif;
        color: var(--color-text);
    }

    .input-search{
        border: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-search input{
        width: 100%;
        border: none;
        padding: 5px;
    }
    .input-search input:focus{
        outline: none;
    }

    .input-search button{
        background: var(--color-text);
        color: #fff;
        border: none;
        font-family: 'Tomorrow', sans-serif;
        font-size: .8em;
        font-weight: 500;
        padding: 8px 13px;
    }
</style>