<template>
    <v-row>
        <v-col cols="12" v-for="(p, index) in products" :key="index" class="product-item">
            <v-row>
                <v-col cols="12" v-if="mobile">
                    <v-row v-if="payment">
                        <v-col cols="2">
                            <div class="img-payment">
                                <img :src="p.image" alt="">
                            </div>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col cols="12">
                                    <h3>{{ p.quantity }} x {{ p.name }}</h3>
                                    <span class="value-payment">{{ p.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
                                </v-col>
                                <v-col cols="12">
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="!payment">
                        <v-col cols="4">
                            <div class="img">
                            <zoom-on-hover 
                                :img-normal="p.image"
                                :scale="1"
                            >
                            </zoom-on-hover>
                            </div>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col cols="10">
                                    <h2>{{ p.name }}</h2>
                                    <span class="value">{{ p.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
                                </v-col>
                                <v-col @click="removeProduct(p)">
                                    <v-icon color="red">
                                        fa fa-trash
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col cols>
                                            <div class="qnt">TAM.</div> 
                                            <input class="input-default input-qnt" type="text" v-model="p.variant" readonly>
                                        </v-col>
                                        <v-col>
                                            <div class="qnt">QUANT.</div> 
                                            <input class="input-default input-qnt" type="text" v-model="p.quantity">
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" v-if="!mobile">
                    <v-row v-if="payment">
                        <v-col cols="2">
                            <div class="img-payment">
                                <img :src="p.image" alt="">
                            </div>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col cols="12">
                                    <h3>{{ p.quantity }} x {{ p.name }}</h3>
                                    <span class="value-payment">{{ p.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
                                </v-col>
                                <v-col cols="12">
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="!payment">
                        <v-col cols="4">
                            <div class="img">
                            <zoom-on-hover 
                                :img-normal="p.image"
                                :scale="1"
                            >
                            </zoom-on-hover>
                            </div>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col cols="9">
                                    <h2>{{ p.name }}</h2>
                                </v-col>
                                <v-col>
                                    <span class="value">{{ p.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col cols="4">
                                            <div class="qnt">TAM.</div> 
                                        </v-col>
                                        <v-col>
                                            <input class="input-default input-qnt" type="text" v-model="p.variant" readonly>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <div class="qnt">QUANT.</div> 
                                        </v-col>
                                        <v-col>
                                            <input class="input-default input-qnt" type="text" v-model="p.quantity">
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col></v-col>
                            </v-row>

                            <button class="lda-btn lda-btn-default btn-remover" @click="removeProduct(p)">REMOVER</button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            
        </v-col>
    </v-row>
</template>

<script>   
export default {
    name: 'CartProducts',
    props: {
        mobile: {
            type: Boolean
        },
        products: {
            type: Array,
            required: true
        },
        payment: {
            type: Boolean
        }
    },
    watch: {
        products(){
            console.log('products')
        }
    },
    methods: {
        removeProduct(product){
            this.$emit('removeProduct', product);
        }
    }
};
</script>

<style scoped>
    h2{
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 10px;
    }
    h3{
        font-size: .9rem;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .product-item {
        margin-bottom: 25px;
    }
    .img {
        cursor: zoom-in;
        padding: 0;
        border: 2pt solid var(--color-primary);
    }

    .img-payment img{
        width: 100%;
    }

    .value {
        font-size: 1.2rem;
        font-weight: 400;
    }

    .value-payment {
        font-size: 1.2rem;
        font-weight: 400;
    }

    .qnt{
        padding-top: 3px;
        font-family: 'Tomorrow', sans-serif;
        font-weight: 700
    }

    .input-qnt{
        width: 100px;
        text-align: center;
        font-weight: 400;
    }

    .input-qnt:read-only{
        background-color: #f5f5f5;
    }

    .btn-remover {
        margin-top: 10vh;
    }
    
</style>