<template>
    <v-row>
        <v-col>
            <div class="artist-card">
                <div class="image" :style="`background-image: url(${artist.profile});`">
                </div>
                <div class="body">
                    <h5>{{ artist.name }}</h5>
                    <small>{{ artist.category }}</small>
                </div>
                <div class="footer">
                    <button class="lda-btn lda-btn-default" @click="goTo(`/perfil/${artist.slug}`)">VER PERFIL</button>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ArtistCardSmall',
    props: {
        artist: {
            type: Object,
            required: true
        }
    },
    methods: {
        goTo(path) {
            this.$router.push(path);
        }
    }
}
</script>

<style scoped>
    .artist-card .image {
        height: 400px;
        background-size: 101%;
        background-position: calc(100%) calc(33%);
        /* border-radius: 5px; */
        border: 1pt solid var(--color-primary);
    }

    
    @media (min-width: 960px) {
        .artist-card .image {
            height: 225px !important;
        }
    }
    @media (min-width: 1024px) {
        .artist-card .image {
            height: 220px !important;
        }
    }
    @media (min-width: 1441px) {
        .artist-card .image {
            height: 230px !important;
        }
    }
    @media (min-width: 600px) {
        .artist-card .image {
            height: 288px;
        }
    }
    @media (max-width: 600px) {
        .artist-card .image {
            height: 310px;
        }
    }


    .artist-card h5 {
        margin-top: 10px;
        margin-bottom: -10px;
        font-size: 1em;
        font-family: "Tomorrow", sans-serif;
    }
    .artist-card .body{
        padding-bottom: 10px;
    }
</style>