<template>
    <v-container class="medium-container">
        <v-row v-if="!artist">
            <v-col cols="12" class="title">
                <h1>PRODUTOS</h1>
            </v-col>
        </v-row>
        <v-row class="desktop">
            <v-col cols="5" sm="3" md="3" lg="2">
                <FilterProducts @handleFilters="handleFilters" :mobile="false"/>
            </v-col>
            <v-col>
                <ProductsSearch :products="products" @searchProduct="searchProduct"/>
            </v-col>
        </v-row>
        <v-row class="mobile">
            <v-col>
                <FilterProducts @handleFilters="handleFilters" :mobile="true"/>
                <ProductsSearch :products="products" @searchProduct="searchProduct"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import FilterProducts from './FilterProducts.vue';
import ProductsSearch from './ProductsSearch.vue';
export default {
    name: 'ProductsPage',
    props: {
        products: {
            type: Array,
            required: true
        },
        artist: {
            type: Number,
            required: false
        },
        loading: {
            type: Boolean,
            required: false
        }
    },
    components: {
        FilterProducts,
        ProductsSearch
    },
    data() {
        return {
            search: '',
            selectedFilters: []
        }
    },
    methods: {
        handleFilters(filters) {
            this.selectedFilters = filters;
            if(this.search) {
                this.$emit('searchProduct', this.search, filters);
                return;
            }
            this.$emit('searchProduct', '', filters);
        },
        searchProduct(search) {
            this.search = search;

            if(this.search == ''){
                this.selectedFilters = [];
                this.$emit('searchProduct', '', []);
                return;
            }

            if(this.selectedFilters) {
                this.$emit('searchProduct', search, this.selectedFilters);
                return;
            }
            this.$emit('searchProduct', search);
        }
    }
};
</script>

<style scoped>
    .medium-container{
        max-width: 1185px;
    }
    @media (min-width: 1904px) {
        .medium-container {
            max-width: 1770px !important;
        }
    }
    @media (min-width: 1264px) {
        .medium-container {
            max-width: 1185px !important;
        }
    }
    @media (min-width: 960px) {
        .medium-container {
            max-width: 890px;
        }
    }

    @media (min-width: 960px) {
        .desktop {
            display: flex;
        }
        .mobile {
            display: none;
        }
    }

    @media (max-width: 600px) {
        .desktop {
            display: none;
        }
        .mobile {
            display: flex;
        }
    }

    .title {
        margin-top: 10vh;
        margin-bottom: 30px;
    }
    .title h1{
        font-size: 2.3em;
        font-weight: 700;
        color: var(--color-primary);
        font-family: "Tomorrow", sans-serif !important;
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }


</style>