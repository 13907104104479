<template>
    <v-container class="medium-container">
        <v-row>
            <v-col cols="12" class="title">
                <h1>CARRINHO</h1>
            </v-col>
        </v-row>
        <v-row class="desktop">
            <v-col cols="7">
                <CartProducts :products="products" :mobile="false" @removeProduct="removeProduct"/>
            </v-col>
            <v-col>
                <CartTotalizer :products="products"/>
            </v-col>
        </v-row>
        <v-row class="mobile">
            <v-col cols="12">
                <CartProducts :products="products" :mobile="true" @removeProduct="removeProduct"/>
            </v-col>
            <v-col>
                <CartTotalizer :products="products"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CartProducts from './CartProducts.vue';
import CartTotalizer from './CartTotalizer.vue';
export default {
    name: 'CartPage',
    props: {
        products: {
            type: Array,
            required: true
        },
    },
    components: {
        CartProducts,
        CartTotalizer
    },
    methods: {
        removeProduct(p){
            this.$emit('removeProduct', p);
        }
    }
};
</script>

<style scoped>
    .medium-container{
        max-width: 1185px;
    }
    @media (min-width: 1904px) {
        .medium-container {
            max-width: 1770px !important;
        }
    }
    @media (min-width: 1264px) {
        .medium-container {
            max-width: 1185px !important;
        }
    }
    @media (min-width: 960px) {
        .medium-container {
            max-width: 890px;
        }
    }

    @media (min-width: 960px) {
        .desktop {
            display: flex;
        }
        .mobile {
            display: none;
        }
    }

    @media (max-width: 600px) {
        .desktop {
            display: none;
        }
        .mobile {
            display: flex;
        }
    }

    .title {
        margin-top: 10vh;
        margin-bottom: 30px;
    }
    .title h1{
        font-size: 2.3em;
        font-weight: 700;
        color: var(--color-primary);
        font-family: "Tomorrow", sans-serif !important;
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }


</style>