<template>
    <v-row>
        <v-col cols="12" sm="6" md="6" lg="8">
            <div class="input-search">
                <input type="text" placeholder="Pesquisar por nome" v-model="search">
                <button @click="searchArtist">PESQUISAR</button>
            </div>
        </v-col>
        <v-col>
            <button class="btn-clear" @click="clearFilters">LIMPAR</button>
        </v-col>
        <v-col cols="12">
            <v-row class="scroll">
                <v-col v-for="artist in artists" :key="artist.id" cols="12" sm="6" md="4" lg="3">
                    <ArtistCardSmall :artist="artist"/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import ArtistCardSmall from './ArtistCardSmall.vue';
export default {
    name: 'ArtistsSearch',
    props: {
        artists: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            search: '',
            filteredArtists: []
        }
    },
    mounted() {
        this.filteredArtists = this.artists;
    },
    components: {
        ArtistCardSmall
    },
    methods: {
        searchArtist() {
            if(this.search.length == 0) return
            if(this.search.length < 3) {
                this.$swal({
                    title: 'Ops!',
                    text: 'Digite pelo menos 3 caracteres para pesquisar',
                    icon: 'warning',
                    confirmButtonText: 'Entendi'
                });
                return;
            }
            this.$emit('searchArtist', this.search);
        },
        clearFilters() {
            this.search = '';
            this.$emit('searchArtist', '');
        }
    }
};
</script>

<style scoped>
    .input-search{
        border: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-search input{
        width: 100%;
        border: none;
        padding: 8px;
    }
    .input-search input:focus{
        outline: none;
    }

    .input-search button{
        background: var(--color-text);
        color: #fff;
        border: none;
        font-family: 'Tomorrow', sans-serif;
        font-size: .8em;
        font-weight: 500;
        padding: 11px 13px;
    }

    .btn-clear{
        background: var(--color-text);
        color: #fff;
        border: none;
        font-family: 'Tomorrow', sans-serif;
        font-size: .8em;
        font-weight: 500;
        padding: 12px 13px;
    }
</style>