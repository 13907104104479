<template>
    <v-row>
        <v-col cols="12" class="subtotal">
            <v-row>
                <v-col>
                    <h2>SUBTOTAL</h2>
                </v-col>
                <v-col style="text-align: right;">
                    <div class="value">{{ subtotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</div>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12">
            <v-row>
                <v-col>
                    <input class="input-default input-cep" type="text" v-model="cep">
                </v-col>
                <v-col>
                    <button class="lda-btn lda-btn-black text-sm mt-1" v-if="!calculating" @click="calculateShipping">CALCULAR FRETE</button>
                    <button class="lda-btn lda-btn-black text-sm mt-1" v-if="calculating" disabled>CALCULANDO</button>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="6" v-if="listShipping">
            <div class="card-shipping pac" @click="selectShipping('pac')">
                <h3>PAC</h3>
                <input type="radio" ref="pac" v-model="selectedShipping" value="pac">
                <div class="value">R$ {{ listShipping.valorpac }} <small>({{ listShipping.prazopac }} {{ parseInt(listShipping.prazopac) == 1 ? 'Dia' : 'Dias' }})</small></div>
            </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" v-if="listShipping">
            <div class="card-shipping sedex" @click="selectShipping('sedex')">
                <h3>SEDEX</h3>
                <input type="radio" ref="sedex" v-model="selectedShipping" value="sedex">
                <div class="value">R$ {{ listShipping.valorsedex }} <small>({{ listShipping.prazosedex }} {{ parseInt(listShipping.prazosedex) == 1 ? 'Dia' : 'Dias' }})</small></div>
            </div>
        </v-col>

        <v-col cols="12">
            <v-row>
                <v-col>
                    <h2>FRETE</h2>
                </v-col>
                <v-col style="text-align: right;">
                    <div class="value">{{ shipping.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</div>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12">
            <v-row>
                <v-col>
                    <h2>TOTAL</h2>
                </v-col>
                <v-col style="text-align: right;">
                    <div class="value">{{ total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</div>
                </v-col>
            </v-row>
        </v-col>
        <v-col>
            <button class="lda-btn lda-btn-black text-md" @click="continueToPayment">FINALIZAR</button>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
export default {
    name: 'CartTotalizer',
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            cep: '',
            calculating: false,
            subtotal: 0,
            shipping: 0,
            total: 0,
            selectedShipping: '',
            listShipping: null
        }
    },
    mounted(){
        this.calculateTotal();
        setInterval(() => {
            this.calculateTotal();
        }, 200);
    },
    methods: {
        calculateTotal(){
            this.subtotal = this.products.reduce((acc, product) => acc + (product.price * parseInt(product.quantity)), 0);
            this.total = this.subtotal + this.shipping;
        },
        async calculateShipping() {
            const cepValid = this.cep.match(/^\d{5}-?\d{3}$/);
            if (!cepValid) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'CEP inválido',
                    text: 'Por favor, insira um CEP válido'
                });
                return;
            }

            try {
                this.calculating = true
                let response = await axios.get(`https://www.cepcerto.com/ws/json-frete/03407040/${this.cep.replace(/\D/g, '')}/2000/10/50/41/2267b617c37153c7614df022a2177df9e4a9c3d1`)
                if (response.data && !response.data.error) {
                    this.listShipping = response.data
                } else {
                    this.showSnackbar('CEP inválido');
                }
                this.calculating = false
            } catch (error) {
                this.calculating = false
                this.showSnackbar('Erro ao calcular o frete');
                console.log(error)
            }
        },
        async selectShipping(type){
            this.selectedShipping = type;
            this.shipping = parseFloat(this.listShipping[`valor${type}`].replace(',', '.'))
            this.calculateTotal();
        },
        async continueToPayment(){
            if(!this.selectedShipping){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Selecione um frete',
                    text: 'Por favor, selecione um frete para continuar'
                });
                return;
            }

            let shipping = {
                type: this.selectedShipping,
                estimatedTime: this.selectedShipping == 'pac' ? this.listShipping.prazopac : this.listShipping.prazosedex,
                cep: this.cep,
                price: this.shipping
            }

            let listShipping = this.listShipping

            localStorage.setItem('shipping', JSON.stringify(shipping));
            localStorage.setItem('listShipping', JSON.stringify(listShipping));
            localStorage.setItem('cart', JSON.stringify(this.products));

            this.$router.push('/pagamento');
        }
    }
};
</script>

<style scoped>
    h2{
        font-family: 'Tomorrow', sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .subtotal{
        margin-bottom: 28px;
    }

    .value{
        margin-top: 4px;
        font-size: 1.2rem;
        font-weight: 400;
    }
    
    .input-cep{
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1.2rem;
    }

    button:disabled{
        cursor: not-allowed;
        background-color: #ccc;
        border: 1px solid #ccc;
    }

    .card-shipping{
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .card-shipping:hover {
        background-color: #f9f9f9;
    }

    .card-shipping input[type="radio"]{
        margin-right: 10px;
        display: block;
        float: right;
    }

    /* CUSTOM RADIO BUTTON */
    .card-shipping input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .card-shipping input[type="radio"] + div {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
    }

    .card-shipping input[type="radio"] + div:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 100%;
        background-color: #fff;
    }

    .card-shipping input[type="radio"]:checked + div:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 100%;
        background-color: var(--color-primary);
    }

    .card-shipping input[type="radio"] + div:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #fff;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        display: none;
    }

    .card-shipping input[type="radio"]:checked + div:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: var(--color-primary);
        top: 5px;
        left: 5px;
        border-radius: 100%;
        display: block;
    }

    .card-shipping h3{
        font-family: 'Tomorrow', sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 10px;
    }
    

    
</style>