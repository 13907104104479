<template>
    <v-row>
        <v-row>
            <v-col cols="12" class="title">
                <h1>VOCÊ TAMBÉM PODE GOSTAR</h1>
            </v-col>
        </v-row>
        <v-col cols="12">
            <v-row class="scroll">
                <v-col v-for="product in products" :key="product.id" cols="6" sm="6" md="4" lg="3">
                    <ProductCard :product="product"/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import ProductCard from './ProductCard.vue';
export default {
    name: 'ProductsSearch',
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            search: '',
            filteredProducts: []
        }
    },
    components: {
        ProductCard
    }
};
</script>

<style scoped>
    .search {
        width: 50%;
        padding: 10px;
        border: 1px solid var(--color-gray-medium);
        margin-bottom: 20px;
    }

    @media (max-width: 600px) {
        .search {
            width: 100%;
        }
    }

    .search:focus {
        outline: none;
    }

    .title {
        margin-top: 10vh;
        margin-bottom: 30px;
    }
    .title h1{
        text-align: center;
        font-size: 2.3em;
        font-weight: 700;
        color: var(--color-primary);
        font-family: "Tomorrow", sans-serif !important;
    }

    /* mobile */
    @media (max-width: 600px) {
        .title h1{
            text-align: center;
            font-size: 2.3em;
            line-height: 1.1;
            font-weight: 700;
            color: var(--color-primary);
            font-family: "Tomorrow", sans-serif !important;
        }
    }
</style>