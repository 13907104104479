<template>
    <v-row>
        <v-col cols="12" class="subtotal">
            <v-row>
                <v-col>
                    <h2>SUBTOTAL</h2>
                </v-col>
                <v-col style="text-align: right;">
                    <div class="value">{{ subtotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" v-if="listShipping && step == 1">
            <div class="card-shipping pac" @click="selectShipping('pac')">
                <h3>PAC</h3>
                <input type="radio" ref="pac" v-model="selectedShipping" value="pac">
                <div class="value">R$ {{ listShipping.valorpac }} <small>({{ listShipping.prazopac }} {{ parseInt(listShipping.prazopac) == 1 ? 'Dia' : 'Dias' }})</small></div>
            </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" v-if="listShipping && step == 1">
            <div class="card-shipping sedex" @click="selectShipping('sedex')">
                <h3>SEDEX</h3>
                <input type="radio" ref="sedex" v-model="selectedShipping" value="sedex">
                <div class="value">R$ {{ listShipping.valorsedex }} <small>({{ listShipping.prazosedex }} {{ parseInt(listShipping.prazosedex) == 1 ? 'Dia' : 'Dias' }})</small></div>
            </div>
        </v-col>

        <v-col cols="12">
            <v-row>
                <v-col>
                    <h2>FRETE</h2>
                </v-col>
                <v-col style="text-align: right;">
                    <div class="value">{{ shipping.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</div>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12">
            <v-row>
                <v-col>
                    <h2>TOTAL</h2>
                </v-col>
                <v-col style="text-align: right;">
                    <div class="value">{{ total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" v-if="step == 1">
            <v-row>
                <v-col cols="12">
                    <h2>Dados de Entrega</h2>
                </v-col>
                <v-col>
                    <input class="input-default input-cep" type="text" v-model="cep" readonly>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <label for="">Endereço</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.address">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <label for="">Número</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.number">
                </v-col>
                <v-col>
                    <label for="">Bairro</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.neighborhood">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <label for="">Cidade</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.city">
                </v-col>
                <v-col>
                    <label for="">Estado</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.state">
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h2>Dados Pessoais</h2>
                </v-col>
                <v-col>
                    <label for="">Nome Completo</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.name">
                </v-col>
                <v-col>
                    <label for="">CPF</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.cpf">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <label for="">E-mail</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.email">
                </v-col>
                <v-col>
                    <label for="">Telefone</label>
                    <input class="input-default input-cep" type="text" v-model="costumerData.phone">
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" v-if="step == 2">
            <div id="paymentBrick_container"></div>
        </v-col>
        <v-col cols="12" v-if="step == 3">
            <div id="statusScreenBrick_container"></div>
        </v-col>
        <v-col>
            <button class="lda-btn lda-btn-black text-md" @click="generatePayment" v-if="step == 1">CONTINUAR</button>
        </v-col>
    </v-row>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import api from "@/commands/api"

import { loadMercadoPago } from "@mercadopago/sdk-js";

async function initializeMercadoPago() {
    await loadMercadoPago();
    if (process.env.VUE_APP_SANDBOX == 'true') {
        console.log('sandbox')
        return new window.MercadoPago("TEST-b5e09dd2-3157-4a22-99b2-37cbba15dae4");
    } else {
        console.log('produção')
        return new window.MercadoPago("APP_USR-68b9ffeb-a7d7-4ac4-9f66-31d50b247f2c");
    }
}

function isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return false
    return true
}


export default {
    name: 'PaymentTotalizer',
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            mp: null,
            step: 1,
            cep: '',
            calculating: false,
            subtotal: 0,
            shipping: 0,
            total: 0,
            selectedShipping: '',
            listShipping: null,
            costumerData:{
                name: "",
                cpf: "",
                email: "",
                phone: "",
                address: "",
                neighborhood: "",
                number: "",
                city: "",
                state: "",
                zip_code: ""
            }
        }
    },
    watch: {
        products(){
            this.calculateTotal();
        }
    },
    async mounted(){
        this.calculateTotal();
        this.mp = await initializeMercadoPago();
        let shippingData = localStorage.getItem('shipping');

        if(shippingData){
            shippingData = JSON.parse(shippingData);
            this.selectedShipping = shippingData.type;
            this.shipping = shippingData.price;
            this.cep = shippingData.cep;
            this.costumerData.zip_code = shippingData.cep;
        }

        let listShipping = localStorage.getItem('listShipping');
        if(listShipping){
            this.listShipping = JSON.parse(listShipping);
        }

        let cepDatas = await this.findCep(this.cep);

        this.costumerData.address = cepDatas.logradouro;
        this.costumerData.state = cepDatas.uf;
        this.costumerData.city = cepDatas.localidade;

        console.log(cepDatas)

        setInterval(() => {
            this.calculateTotal();
        }, 200);

    },
    methods: {
        calculateTotal(){
            this.subtotal = this.products.reduce((acc, product) => acc + (product.price * parseInt(product.quantity)), 0);
            this.total = this.subtotal + this.shipping;
        },
        async calculateShipping() {
            const cepValid = this.cep.match(/^\d{5}-?\d{3}$/);
            if (!cepValid) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'CEP inválido',
                    text: 'Por favor, insira um CEP válido'
                });
                return;
            }

            try {
                this.calculating = true
                let response = await axios.get(`https://www.cepcerto.com/ws/json-frete/03407040/${this.cep.replace(/\D/g, '')}/2000/10/50/41/2267b617c37153c7614df022a2177df9e4a9c3d1`)
                if (response.data && !response.data.error) {
                    this.listShipping = response.data
                } else {
                    this.showSnackbar('CEP inválido');
                }
                this.calculating = false
            } catch (error) {
                this.calculating = false
                this.$swal.fire({
                    icon: 'error',
                    title: 'Erro ao calcular frete',
                    text: 'Ocorreu um erro ao calcular o frete, por favor, tente novamente'
                });
                console.log(error)
            }
        },
        async selectShipping(type){
            this.selectedShipping = type;
            this.shipping = parseFloat(this.listShipping[`valor${type}`].replace(',', '.'))
            this.calculateTotal();
        },
        async findCep(cep) {
            try {
                let response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                return response.data
            } catch (error) {
                console.log(error)
            }
        },
        async initMP(){
            if(window.paymentBrickController){
                window.paymentBrickController.unmount();
            }

            const bricksBuilder = this.mp.bricks();
            let app = this;
            const renderPaymentBrick = async (bricksBuilder) => {
                const settings = {
                    initialization: {
                    amount: this.total,
                    // amount: 1,
                    payer: {
                        firstName: "",
                        lastName: "",
                        email: "",
                    },
                    },
                    customization: {
                    visual: {
                        style: {
                        theme: "default",
                        },
                    },
                    paymentMethods: {
                        creditCard: "all",
                        atm: "all",
                        bankTransfer: "all",
                        maxInstallments: 12
                    },
                    },
                    callbacks: {
                        onReady: () => {
                            /*
                            Callback chamado quando o Brick está pronto.
                            Aqui, você pode ocultar seu site, por exemplo.
                            */
                        },
                        onSubmit: ({ formData }) => {
                            app.pay(formData);
                        },
                        onError: (error) => {
                            // callback chamado para todos os casos de erro do Brick
                            console.error(error);
                        },
                    },
                };
                window.paymentBrickController = await bricksBuilder.create(
                    "payment",
                    "paymentBrick_container",
                    settings
                );
            };
            renderPaymentBrick(bricksBuilder);
        },
        async initMPStatus(id){
            if(window.paymentBrickController){
                window.paymentBrickController.unmount();
            }
            if(window.statusScreenBrickController){
                window.statusScreenBrickController.unmount();
            }
            const bricksBuilder = this.mp.bricks();
            const renderStatusScreenBrick = async (bricksBuilder) => {
            const settings = {
                initialization: {
                paymentId: id, // Payment identifier, from which the status will be checked
                },
                customization: {
                visual: {
                    showExternalReference: false,
                    hideStatusDetails: true,
                    hideTransactionDate: true,
                    style: {
                    theme: 'dark', // 'default' | 'dark' | 'bootstrap' | 'flat'
                    }
                },
                backUrls: {
                    'error': 'https://google.com',
                    'return': 'https://google.com'
                }
                },
                callbacks: {
                onReady: () => {
                    // Callback called when Brick is ready
                },
                onError: (error) => {
                    // Callback called for all Brick error cases
                },
                },
            };
            window.statusScreenBrickController = await bricksBuilder.create('statusScreen', 'statusScreenBrick_container', settings);
            };
            renderStatusScreenBrick(bricksBuilder);
        },
        async validateWhatsapp(){
            try {
                let response = await api.get(`whatsapp/validate/${this.costumerData.phone}`);
                if(response.data.error == false){
                    return true
                }

                return false
            } catch (error) {
                console.log(error)
                return false          
            }
        },
        async validateForm(){
            if(this.step == 1){
                if(this.costumerData.address == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o endereço de entrega'
                    });
                    return false
                }

                if(this.costumerData.number == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o número do endereço de entrega'
                    });
                    return false
                }

                if(this.costumerData.neighborhood == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o bairro do endereço de entrega'
                    });
                    return false
                }

                if(this.costumerData.city == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe a cidade do endereço de entrega'
                    });
                    return false
                }

                if(this.costumerData.state == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o estado do endereço de entrega'
                    });
                    return false
                }

                if(this.costumerData.name == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o nome completo'
                    });
                    return false
                }

                if(this.costumerData.name.split(' ').length < 2){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o nome completo'
                    });
                    return false
                }

                if(this.costumerData.cpf == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o CPF'
                    });
                    return false
                }

                if(!isValidCPF(this.costumerData.cpf)){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe um CPF válido'
                    });
                    return false
                }

                if(this.costumerData.email == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o e-mail'
                    });
                    return false
                }

                if(this.costumerData.phone == ''){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Preencha todos os campos',
                        text: 'Informe o telefone'
                    });
                    return false
                }

                if(!await this.validateWhatsapp()){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Telefone inválido',
                        text: 'Informe um número de telefone que seja válido no WhatsApp'
                    });
                    return false
                }

                return true
            }

            return false
        },
        async generatePayment(){
            if(this.step == 1){
                if(await this.validateForm()){
                    this.step = 2;
                    this.$emit('updateStep', 2);
                    this.initMP();
                }
            }
        },
        async intervalVerifyPayment(id){
            let interval = setInterval(async () => {
                let response = await api.get(`order/charge/${id}`);
                if(response.data.status == 'approved'){
                    clearInterval(interval);
                    this.step = 3;
                    this.initMPStatus(id);
                    this.$store.dispatch('clearCart');
                }
            }, 5000);
        },
        async pay(formData){
            let dataInsert = {
                costumer: this.costumerData,
                payment: formData,
                shipping: {
                    type: this.selectedShipping.toUpperCase(),
                    value: this.shipping
                },
                products: this.products
            }

            console.log(dataInsert)

            try {
                let response = await api.post('/orders', dataInsert);
                if(!response.data.charge){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Erro ao processar pagamento',
                        text: 'Ocorreu um erro ao processar o pagamento, por favor, tente novamente'
                    });
                    return;
                }
                this.step = 3;
                this.initMPStatus(response.data.charge.id);

                if(response.data.charge.status == 'approved'){
                    this.$store.dispatch('clearCart');
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Pagamento aprovado',
                        text: 'Seu pagamento foi aprovado com sucesso, em breve você receberá um whatsapp com os detalhes da sua compra'
                    });
                }

                if(response.data.charge.payment_method_id == 'pix'){
                    this.intervalVerifyPayment(response.data.charge.id);
                }


            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: 'Erro ao processar pagamento',
                    text: 'Ocorreu um erro ao processar o pagamento, por favor, tente novamente'
                });
            }
        }
    }
};
</script>

<style scoped>
    h2{
        font-family: 'Tomorrow', sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .subtotal{
        margin-bottom: 28px;
    }

    .value{
        margin-top: 4px;
        font-size: 1.2rem;
        font-weight: 400;
    }
    
    .input-cep{
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1.2rem;
    }

    .input-cep:read-only{
        cursor: not-allowed;
        background-color: #f1f1f1;
    }

    button:disabled{
        cursor: not-allowed;
        background-color: #ccc;
        border: 1px solid #ccc;
    }

    .card-shipping{
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .card-shipping:hover {
        background-color: #f9f9f9;
    }

    .card-shipping input[type="radio"]{
        margin-right: 10px;
        display: block;
        float: right;
    }

    /* CUSTOM RADIO BUTTON */
    .card-shipping input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .card-shipping input[type="radio"] + div {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
    }

    .card-shipping input[type="radio"] + div:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 100%;
        background-color: #fff;
    }

    .card-shipping input[type="radio"]:checked + div:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 100%;
        background-color: var(--color-primary);
    }

    .card-shipping input[type="radio"] + div:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #fff;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        display: none;
    }

    .card-shipping input[type="radio"]:checked + div:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: var(--color-primary);
        top: 5px;
        left: 5px;
        border-radius: 100%;
        display: block;
    }

    .card-shipping h3{
        font-family: 'Tomorrow', sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 10px;
    }
    

    
</style>