<template>
    <v-app>
        <NavBar />
        <ArtistsPage :artists="artists" :filterItems="filterItems" @searchArtist="getArtists"/>
        <SearchSection />
        <FooterSection />
    </v-app>
</template>

<script>
import NavBar from '@/components/default/NavBar.vue';
import ArtistsPage from '@/components/artistspage/ArtistsPage.vue';
import SearchSection from '@/components/default/SearchSection.vue';
import FooterSection from '@/components/default/FooterSection.vue';
import api from "@/commands/api"
export default {
    name: 'ArtistsView',
    components: {
        NavBar,
        ArtistsPage,
        SearchSection,
        FooterSection
    },
    data() {
        return {
            filterItems:[
                {
                    id: 1,
                    slug: 'atleta',
                    name: 'Atletas',
                    selected: false
                },
                {
                    id: 2,
                    slug: 'basquete',
                    name: 'Basquete',
                    selected: false
                },
                {
                    id: 3,
                    slug: 'boxe',
                    name: 'Boxe',
                    selected: false
                },
                {
                    id: 4,
                    slug: 'ciclista',
                    name: 'Ciclistas',
                    selected: false
                },
                {
                    id: 5,
                    slug: 'cantor',
                    name: 'Cantores',
                    selected: false
                },
                {
                    id: 6,
                    slug: 'DJ',
                    name: 'DJs',
                    selected: false
                },
                
                {
                    id: 7,
                    slug: 'MC',
                    name: 'MCs',
                    selected: false
                }
            ],
            artists: [],
            artistsMeta: {}
        };
    },
    async created() {
        await this.getArtists();
    },
    methods: {
        async getArtists(search = '', filters = '') {
            try {
                let q = '';
                q += '?o=created_at&ot=desc&per_page=10?page=1';
                if(search) {
                    q += `&q=${search}`;
                }

                if(filters) {
                    q += `&c=${filters.filter(item => item.selected).map(item => item.slug).join(',')}`;
                }

                const response = await api.get(`/stores${q}`);
                this.artists = response.data.data;
                this.artistsMeta = response.data.meta;
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>

<style scoped>
    
</style>