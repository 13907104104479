<template>
    <v-app>
        <NavBar />
        <CartPage :products="products" @removeProduct="removeProduct"/>
        <SearchSection />
        <FooterSection />
    </v-app>
</template>

<script>
import NavBar from '@/components/default/NavBar.vue';
import CartPage from '@/components/cartpage/CartPage.vue';
import SearchSection from '@/components/default/SearchSection.vue';
import FooterSection from '@/components/default/FooterSection.vue';
export default {
    name: 'CartView',
    components: {
        NavBar,
        CartPage,
        SearchSection,
        FooterSection
    },
    data() {
        return {
            products: []
        };
    },
    created() {
        this.getProductInCart();
    },
    methods: {
        async getProductInCart(){
            await this.$store.dispatch('updateCartIfLocalStorageExists');
            this.products = this.$store.getters.cart;
        },
        removeProduct(p){
            this.$store.dispatch('removeProductFromCart', p);
            this.products = this.$store.getters.cart;
        }
    }
};
</script>

<style scoped>
    
</style>