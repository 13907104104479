<template>
    <div>
        <div class="sticky">
            <v-container class="navbar">
                <v-row>
                    <v-col class="logo" cols="12" md="3" sm="4" lg="4">
                        <img :src="require('@/assets/img/logo.png')" alt="" @click="goTo('/')">
                    </v-col>
                    <v-col class="menu">
                        <v-btn class="menu-item" text @click="goTo('/')">Home</v-btn>
                        <v-btn class="menu-item" text @click="goTo('/artistas')">Artistas</v-btn>
                        <v-btn class="menu-item" text @click="goTo('/produtos')">Loja</v-btn>
                    </v-col>
                    <v-col class="cart" cols="12" md="1" sm="1" lg="1">
                        <v-badge
                            color="black"
                            :content="cartQnt"
                            overlap
                        >
                            <img :src="require('@/assets/icons/cart.svg')" alt="" @click="goTo('/carrinho')">
                        </v-badge>
                        
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="navbar-mobile">
                <v-row>
                    <v-col class="menu" cols="3">
                        <v-btn
                            icon
                            @click.stop="toggleSidebar"
                        >
                            <v-icon>fa fa-bars</v-icon>
                        </v-btn>

                        
                    </v-col>
                    <v-col class="logo" cols="6">
                        <img :src="require('@/assets/img/logo.png')" alt="" @click="goTo('/')">
                    </v-col>
                    <v-col class="cart" cols="3">
                        <v-badge
                            color="black"
                            :content="cartQnt"
                            overlap
                            class="cart-badge"
                        >
                            <img :src="require('@/assets/icons/cart.svg')" alt="" @click="goTo('/carrinho')">
                        </v-badge>
                        
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        class="drawer"
        >
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>
                <!-- <img :src="require('@/assets/img/logo.png')" alt="" class="logo-menu" @click="goTo('/')"> -->
                </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider style="margin-bottom: 10px !important"></v-divider>

            <v-list dense>
                <v-list-item>
                    <v-btn class="menu-item" text @click="goTo('/')">Home</v-btn>
                </v-list-item>
                <v-list-item>
                    <v-btn class="menu-item" text @click="goTo('/artistas')">Artistas</v-btn>
                </v-list-item>
                <v-list-item>
                    <v-btn class="menu-item" text @click="goTo('/produtos')">Loja</v-btn>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
    
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            drawer: false,
            group: null,
            cartQnt: 0
        }
    },
    created() {
        this.cartQnt = this.$store.getters.cartQnt
    },
    methods: {
        async toggleSidebar() {
            await this.$vuetify.goTo(0);
            this.drawer = !this.drawer;
        },
        goTo(route) {
            if (this.$route.path === route) return;
            this.$router.push(route);
        }
    }
};
</script>

<style scoped>
    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
        background-color: white;
        border: 1px solid #e0e0e0;
    }
    .navbar .logo img {
        width: 100%;
        cursor: pointer;
    }

    .navbar .menu {
        padding-top: 20px;
    }

    .navbar .menu .menu-item {
        margin: 0 10px;
        font-weight: 400;
    }

    .navbar .cart img {
        width: 30px;
        cursor: pointer;
    }

    .navbar .cart{
        padding-top: 1.4rem;
    }




    .navbar-mobile .logo img {
        width: 70%;
        margin-top: 20px;
        display: block;
        margin: 0 auto;
    }

    .navbar-mobile .logo-menu{
        width: 50%;
        display: block;
        margin: 0 auto;
    }

    .drawer .menu-item {
        width: 100%;
        text-align: left;
    }

    .navbar-mobile .cart{
        padding-top: 1rem;
    }

    .navbar-mobile .cart img {
        width: 25px;
    }

    .navbar-mobile .cart-badge {
        display: block;
        float: right;
        margin-right: 30px;
    }
    

    @media (min-width: 1264px) {
        .logo img {
            width: 150px !important;
        }

        .navbar-mobile {
            display: none !important;
        }
        .navbar {
            display: block !important;
        }
    }

    @media (min-width: 960px) {
        .logo img {
            width: 80%;
        }
        .navbar-mobile {
            display: none !important;
        }
        .navbar {
            display: block !important;
        }
    }
    @media (min-width: 600px) {
        .navbar-mobile {
            display: block;
        }
        .navbar-mobile .logo img {
            width: 40%;
            margin-top: 20px;
            display: block;
            margin: 0 auto;
        }
        .navbar-mobile .cart{
            padding-top: 1.4rem;
        }
        .navbar {
            display: none;
        }
    }

    @media (max-width: 600px) {
        .navbar-mobile{
            display: block;
        }
        .navbar{
            display: none;
        }
    }

    
</style>