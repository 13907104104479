import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
   cart: [],
   cartQnt: 0,
  },
  mutations: {
    async addToCart(state, item) {
      state.cart.push(item);
      localStorage.setItem('cart', JSON.stringify(state.cart));
      state.cartQnt = state.cart.length;
    }
  },
  actions: {
    async updateCartIfLocalStorageExists({ state }) {
      if(localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'));
        state.cartQnt = state.cart.length;
      }
    },
    async removeProductFromCart({ state }, product) {
      state.cart = state.cart.filter(item => item.id_product !== product.id_product);
      localStorage.setItem('cart', JSON.stringify(state.cart));
      state.cartQnt = state.cart.length;
    },
    async clearCart({ state }) {
      state.cart = [];
      localStorage.setItem('cart', JSON.stringify(state.cart));
      state.cartQnt = state.cart.length;
    }
  },
  getters: {
    cart: state => state.cart,
    cartTotal: state => state.cart.reduce((total, item) => total + item.price, 0),
    cartQnt: state => state.cart.length
  }
});
