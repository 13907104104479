<template>
    <v-container class="product-container medium-container">
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" sm="6" md="6" lg="4">
                        <div class="container-images">
                            <img :src="productShow.image" class="active-img" alt="">
                            <!-- <div class="images-list">
                                <img 
                                    v-for="(p, index) in product.images"
                                    :key="index"
                                    :src="p" 
                                    :style="{border: selectedImage === p ? '1px solid var(--color-primary)' : '1px solid var(--color-gray-medium)'}"
                                    @click="selectedImage = p"
                                >
                            </div> -->
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" class="product-data">
                        <h2>{{ productShow.name }}</h2>
                        <p>{{ productShow.description }}</p>

                        <h4>TAMANHO</h4>
                        <v-row>
                            <v-col v-for="(size, index) in productShow.variants" :key="index" cols="2" sm="2" md="1" lg="1">
                                <button 
                                    class="size-btn"
                                    @click="sizeSelected = size"
                                    :style="{backgroundColor: sizeSelected === size ? 'var(--color-primary)' : 'transparent', border: sizeSelected === size ? '1px solid var(--color-primary)' : '1px solid var(--color-gray-medium)', color: sizeSelected === size ? 'var(--color-white)' : 'var(--color-gray-dark)', color: sizeSelected === size ? 'white' : 'var(--color-text)'}"
                                >
                                    {{ size }}
                                </button>
                            </v-col>
                        </v-row>

                       <div class="div-qnt">
                            <h4>QUANTIDADE</h4>
                            <v-row>
                                <v-col>
                                    <div class="input-qnt">
                                        <v-row>
                                            <v-col>
                                                <v-icon class="input-qnt-icon" style="margin-left: 10px;" @click="qnt--">
                                                    fa fa-minus
                                                </v-icon>
                                            </v-col>
                                            <v-col  style="text-align: right;">
                                                <input type="text" class="qnt" v-model="qnt">
                                            </v-col>
                                            <v-col style="text-align: right;">
                                                <v-icon class="input-qnt-icon" style="margin-right: 10px;" @click="qnt++">
                                                    fa fa-plus
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                                <v-col>
                                    <h5 class="value">{{  productShow.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</h5>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <button class="lda-btn lda-btn-black" @click="addToCart">ADICIONAR AO CARRINHO</button>
                                </v-col>
                            </v-row>
                       </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-container class="medium-container" style="padding-top: 100px" v-if="!loading">
            <AlternativeProducts :products="products"/>
        </v-container>
    </v-container>
</template>

<script>

import AlternativeProducts from '../productspage/AlternativeProducts.vue';
export default {
    name: 'ProductPage',
    props: {
        product: {
            type: Object,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return {
            productShow: {
                id: 0,
                name: '',
                image: '',
                description: '',
                value: 0
            },
            selectedImage: null,
            sizeSelected: null,
            qnt: 1
        }
    },
    created(){
        console.log(this.product)
    },
    mounted(){
        console.log(this.product)
        this.productShow.id = this.product.id
        this.productShow.name = this.product.name
        this.productShow.image = this.product.images.filter(x => x.position == 1)[0].src
        this.productShow.description = this.product.description
        this.productShow.variants = JSON.parse(this.product.variants)
        this.productShow.value = this.product.salePrice == null ? 0 : this.product.salePrice
    },
    components: {
        AlternativeProducts
    },
    methods:{
        async addToCart(){
            if(this.sizeSelected === null){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Selecione um tamanho',
                });
                return;
            }
            if(this.qnt <= 0){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Selecione uma quantidade',
                });
                return;
            }
            const product = {
                id_product: this.product.id,
                id_store: this.product.idStore,
                variant_id: this.sizeSelected,
                name: this.product.name,
                variant: this.sizeSelected,
                image: this.product.images[0].src,
                quantity: this.qnt,
                original_price: this.product.originalPrice,
                cost_price: this.product.costPrice,
                price: this.product.salePrice,
            }
            this.$store.commit('addToCart', product);
            console.log(this.$store.state.cart);
            this.$router.push('/carrinho');
        }
    }
};
</script>

<style scoped>
    .medium-container{
        max-width: 1185px;
    }
    @media (min-width: 1904px) {
        .medium-container {
            max-width: 1770px !important;
        }
    }
    @media (min-width: 1264px) {
        .medium-container {
            max-width: 1185px !important;
        }
    }
    @media (min-width: 960px) {
        .medium-container {
            max-width: 890px;
        }
    }

    .product-container{
        margin-top: 10vh;
    }

    .primary-img{
        width: 320px;
        height: 320px;
        display: block;
        margin: 0 auto;
        border-radius: 10px;
    }

    .active-img{
        width: 100%;
        display: block;
        margin: 0 auto;
        border: 1px solid var(--color-primary);
        margin-bottom: 20px;
    }

    .images-list{
        width: 100%;
        display: block;
        margin: 0 auto;
        overflow: auto;
        white-space: nowrap;
        margin-bottom: 20px;
    }

    .images-list img{
        cursor: pointer;
        width: 120px !important;
        border: 1px solid var(--color-gray-medium);
        margin-right: 5px;
    }

    .product-data h2{
        font-size: 2.3em;
        font-weight: 700;
        color: var(--color-primary);
        font-family: "Tomorrow", sans-serif !important;
    }

    .size-btn{
        background-color: var(--color-gray-medium);
        color: var(--color-white);
        border: none;
        padding: 4px 12px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        font-weight: 700;
        font-family: "Tomorrow", sans-serif !important;
    }

    .product-data h4{
        font-size: 1.1em;
        font-weight: 700;
        color: var(--color-text);
        font-family: "Tomorrow", sans-serif !important;
    }

    .value{
        font-size: 1.8em;
        font-weight: 700;
        color: var(--color-text);
        font-family: "Tomorrow", sans-serif !important;
    }

    .input-qnt{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--color-gray-medium);
        padding: 5px;
    }

    .input-qnt-icon{
        cursor: pointer;
        font-size: 1em;
        color: var(--color-text);
    }

    .qnt{
        width: 50px;
        height: 30px;
        border: none;
        text-align: center;
        font-size: 1.1em;
        font-weight: 700;
        color: var(--color-text);
    }

    .div-qnt{
        margin-top: 20vh;
    }

    @media (min-width: 1024px) and (max-width: 1263px) {
        .div-qnt{
            margin-top: 31.3vh !important;
        }
    }

    @media (min-width: 960px) and (max-width: 1023px) {
        .div-qnt{
            margin-top: 50vh !important;
        }
    }

    @media (min-width: 600px) and (max-width: 959px) {
        .div-qnt{
            margin-top: 20vh !important;
        }
    }

    @media (max-width: 599px) {
        .div-qnt{
            margin-top: 5vh !important;
        }
    }
</style>