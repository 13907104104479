<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="artist-card">
                    <div class="body">
                        <img :src="artist.profile" alt="">
                        <h5>{{ artist.name }}</h5>
                        <small>{{ artist.category }}</small>
                    </div>
                    <div class="footer">
                        <button class="lda-btn lda-btn-default" @click="viewProfile">VER PERFIL</button>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// {
//     id: 3,
//     name: 'MC LIPI',
//     image: require('@/assets/img/artists/artista3.png'),
//     role: 'CANTOR'
// }
export default {
    name: 'ArtistCard',
    props: {
        artist: {
            type: Object,
            required: true
        }
    },
    methods: {
        viewProfile() {
            this.$router.push(`/perfil/${this.artist.slug}`);
        }
    }
}
</script>

<style scoped>
    .artist-card img {
        width: 100%;
        border-radius: 10px;
        aspect-ratio: 2/3;
    }
    .artist-card h5 {
        margin-top: 10px;
        margin-bottom: -10px;
        font-size: 1.2em;
        font-family: "Tomorrow", sans-serif;
    }
    .artist-card .body{
        padding-bottom: 10px;
    }
</style>